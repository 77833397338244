import React from "react";
import { Container, Row, Col } from "reactstrap";
import Supplier from "./Supplier";
import MiniLoader from "views/universal/Loader/MiniLoader/MiniLoader";

const SuppliersList = (props) => {
  const { suppliers, isLoading, isAuthenticated, isSupplier, cartType, loadMore, documents } = props;

  return (
    <section className="p-section">
      <Container id="SupplierListContainer">
          <Row>
            <Col sm={12}>
              <div className="article-bloc">
                <Row>
                  <Col md="2" className="text-left">
                    <p>
                      <b>Catégorie</b>
                    </p>
                  </Col>
                  <Col md="1" className="text-left">
                    <p>
                      <b>Département</b>
                    </p>
                  </Col>
                  {cartType === "formalized" && (
                    <Col md="1" className="text-left">
                      <p>
                        <b>Rang</b>
                      </p>
                    </Col>
                  )}
                  <Col md="3" className="text-left">
                    <p>
                      <b>Entreprise</b>
                    </p>
                  </Col>
                  <Col
                    md={cartType === "formalized" ? "2" : "4"}
                    className="text-left"
                  >
                    <p>
                      <b>Information</b>
                    </p>
                  </Col>
                  {cartType === "formalized" && (
                    <Col md="3" className="text-center">
                      <p>
                        <b>Documents</b>
                      </p>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
            {suppliers.length > 0 ? 
              <>
                {suppliers.filter((supplier) => {return supplier;})
                .map((supplier, index) => (
                  <Supplier
                    supplier={supplier}
                    index={index}
                    isAuthenticated={isAuthenticated}
                    isSupplier={isSupplier}
                    key={index}
                    cartType={cartType}
                    documents={documents}
                  />
                ))}
                {/* <Col sm={12}>
                  {isLoading ? 
                    <MiniLoader />
                  :
                    <button onClick={() => loadMore()} className="btn-green float-right">
                      Charger plus
                    </button>
                  }
                </Col> */}
              </>
            :
            <Col sm={12}>
              <div
                className="article-bloc"
                style={{ textAlign: "center", padding: "60px 30px" }}
              >
                {isLoading ? 
                  <MiniLoader />
                :
                  <>Lancer une recherche ci-dessus pour afficher des résultats</>
                }
              </div>
            </Col>
            }
          </Row>
      </Container>
    </section>
  );
};

export default SuppliersList;

import React, { useState } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import { useDispatch } from "react-redux";
import { storeOperations } from "state/ducks/store";
import iconSearch from 'assets/img/icon_search.svg';
import { useHistory } from "react-router-dom";
import "./Filters.css";

const Filters = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    lots,
    filters
  } = props

  const [searchPrestation, setSearchPrestation] = useState(filters.prestation);

  const handleSearch = (e) => {
    e.preventDefault(); // évite le rafraîchissement de la page
    dispatch(storeOperations.filtersLots(filters.category, searchPrestation, filters.edition));
  };

  return (
    <nav id="Filters" style={{backgroundColor: 'transparent'}}>
      <Container>
        <Row>
          <Col lg="3" style={{paddingBottom: '10px'}}>
          </Col>

          <Col lg="6">
            <Form onSubmit={handleSearch}>
              <FormGroup className="mb-0">
                <Input
                  type="text"
                  name="text"
                  id="prestation"
                  placeholder="Saisir une prestation, ex : Diagnostic"
                  value={searchPrestation}
                  onChange={(e) => setSearchPrestation(e.target.value)}
                  style={{border: "1px solid #7e7e7e", borderRadius: "5px", padding: "10px"}}
                />
                <button type="submit" className="icon-search">
                  <img src={iconSearch} alt="icon search filter" />
                </button>
              </FormGroup>
            </Form>
          </Col>

          <Col lg="3">
            {/* ... */}
          </Col>
        </Row>

        <p style={{marginTop: '20px', alignContent: 'center', textAlign: 'center'}}>
          Si vous le souhaitez, les prestataires sont en mesure de pré-remplir eux mêmes des projets de paniers pour vous faire gagner du temps. N’hésitez pas à les contacter (<a href="/prestataires/formalise" style={{textDecoration: 'underline'}}>voir l’annuaire</a>).
        </p>
      </Container>
    </nav>
  );
};

export default Filters;

import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import FormAddEdit from "../Forms/FormAddEdit";

const ModalForm = (props) => {
  const [modal, setModal] = useState(false);

  const { admin, item, buttonLabel } = props;

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const label = buttonLabel;

  let button = "";
  let title = "";

  if (label === "Editer") {
    button = (
      <Button
        className="btn-green float-right"
        onClick={toggle}
        style={{ float: "left", marginRight: "10px", fontSize: "75%" }}
      >
        {label}
      </Button>
    );
    title = "Editer";
  }  else {
    button = (
      <Button
        className="btn-green float-right"
        onClick={toggle}
        style={{ float: "left", marginRight: "10px", fontSize: "75%" }}
      >
        {label}
      </Button>
    );
    title = "Ajouter";
  }

  return (
    <div>
      {button}
      <Modal isOpen={modal} toggle={toggle} size="lg" style={{ width: "100%" }}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {title}
        </ModalHeader>
        <ModalBody>
          <FormAddEdit
            admin={admin}
            item={item}
            closeModal={() => setModal(false)}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalForm;

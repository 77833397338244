import React, { useState } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import "./Filters.css";
import { useDispatch } from "react-redux";
import { storeOperations } from "state/ducks/store";
import { useHistory } from "react-router-dom";
import iconSearch from 'assets/img/icon_search.svg';

const Filters = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { categories, filters } = props;

  // On force une valeur par défaut si jamais filters.prestation est undefined
  const [category] = useState(
    filters.category === "all" && categories[0] !== undefined
      ? categories[0].id
      : filters.category
  );
  const [department] = useState(filters.department);
  const [searchPrestation, setSearchPrestation] = useState(filters.prestation || "");


  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(
      `/catalogue/libre?category=${category}&department=${department}`
    );
    dispatch(
      storeOperations.getSuppliersFromFreeArticle(
        category,
        department,
        searchPrestation
      )
    );
  };

  return (
    <nav id="Filters" style={{backgroundColor: 'transparent'}}>
      <Container>
        <Row>
          <Col lg="3">
          </Col>

          <Col lg="6">
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-0">
                <Input
                  type="text"
                  name="text"
                  id="prestation"
                  placeholder="Saisir une prestation, ex : Diagnostic"
                  value={searchPrestation}
                  onChange={(e) => setSearchPrestation(e.target.value)}
                  style={{border: "1px solid #7e7e7e", borderRadius: "5px", padding: "10px"}}
                />
                <button type="submit" className="icon-search">
                  <img src={iconSearch} alt="icon search filter" />
                </button>
              </FormGroup>
            </Form>
          </Col>

          <Col lg="3">
          </Col>
        </Row>
      </Container>
    </nav>
  );
};

Filters.propTypes = {};

Filters.defaultProps = {};

export default Filters;

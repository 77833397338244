import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { FormGroup, Row, Col, Input } from 'reactstrap';
import LoadingSvg from 'assets/img/icon-preloader-connect.svg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { modalsOperations } from 'state/ducks/modal'
import { sessionOperations } from "state/ducks/session";

const Resend = (props) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [showEmailForm, setShowEmailForm] = useState(true);

  const isMounted = React.useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Merci de renseigner votre mail').required('Ce champ est requis'),
    }),
    onSubmit: async values => {
      setLoading(true);
      try {
        const result = await dispatch(sessionOperations.resetPassword(values));
        if (isMounted.current) {
          if (result.data.suppliers && result.data.suppliers.length > 0) {
            setContracts(result.data.suppliers);
            setShowEmailForm(false);
          } else {
            dispatch(modalsOperations.getModalStatus('message', true, null, result.data.message));
          }
        }
      } catch (error) {
        // if (isMounted.current) {
        //   dispatch(modalsOperations.getModalStatus('message', true, null, result.data.message));
        // }
      }
      if (isMounted.current) {
        setLoading(false);
      }
    },
  });

  const handleContractSelect = async (contractId) => {
    try {
      setLoading(true);
      await dispatch(sessionOperations.resetPasswordWithContract(contractId));
      if (isMounted.current) {
        dispatch(modalsOperations.getModalStatus('message', true, null, "Un email contenant vos identifiants a été envoyé"));
      }
    }
    catch (error) {
      if (isMounted.current) {
        dispatch(modalsOperations.getModalStatus('message', true, null, "Une erreur est survenue"));
      }
    }
    finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  return (
    <Row>
      <Col md="12">
        {showEmailForm ? (
          <form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Input
                name="email"
                id="email"
                placeholder="Votre email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="input-error">{formik.errors.email}</div>
              ) : null}
            </FormGroup>

            <div>
              <button
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                id="btn-connect-modal"
                type="submit"
                className="btn-green"
                disabled={loading}
              >
                <div style={{ lineHeight: "1" }}>Me renvoyer mes identifiants</div>
                {loading && (
                  <div className="preloader-connect-user">
                    <img src={LoadingSvg} alt="loader" />
                  </div>
                )}
              </button>
            </div>
          </form>
        ) : (
          <div>
            <h4>Sélectionnez votre contrat :</h4>
            {contracts.map((contract) => (
              <button
                key={contract.id}
                className="btn-white mb-2 w-100"
                onClick={() => handleContractSelect(contract.id)}
              >
                {contract.name} - {contract.type === "formalized" ? contract.lot + " - Formalisé - " + contract.marketNumber : "Libre"}
              </button>
            ))}
          </div>
        )}
      </Col>
      <Col md="12">
        <p
          style={{ textAlign: "center", marginBottom: "0px", marginTop: "1rem", color: "#DB7124", cursor: "pointer" }}
          onClick={() => dispatch(modalsOperations.getModalStatus('login', true, null))}
        >
          Retourner à la connexion
        </p>
      </Col>
    </Row>
  )
}


export default Resend

import React, { useState } from "react";
import Article from './Article'
import ArticleWithoutSupplier from './ArticleWithoutSupplier'

const LotsDetails = (props) => {

  const {
    departmentCode,
    lots,
    filters,
    isAuthenticated,
    cartSelector,
    user
  } = props

  const [detailToggleArticleId, setDetailToggleArticleId] = useState(null);

  let thisFilteredLots = lots;
  // Filtre les lots s'il y a une catégorie
  if (filters.category !== 'all') {
    thisFilteredLots = thisFilteredLots.filter((lot) => {
      return lot.code.includes(parseInt(filters.category));
    })
  }
  // Filtre ensuite les articles dans les lots s'il y a une recherche texte
  if (filters.prestation !== '') {
    thisFilteredLots = thisFilteredLots.filter((lot) => {
      let filteredArticles = lot.articles.filter(function (article) {
        return article.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(filters.prestation.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase());
      })
      if (filteredArticles.length > 0) {
        lot.articles = filteredArticles;
        return lot;
      }
      return false;
    })
  }

  return (

    <div>
      {thisFilteredLots.map((lot) =>
        <div className="bloc-lot" key={'ld-'+lot.code}>
          {/* <div className="title-bloc-lot">
              <p>{lot.name}<span style={{paddingLeft: "84px"}}></span></p>
          </div> */}
          <div id={'d'+lot.code}>
            {cartSelector && lot?.articles && lot.articles.map((article) => {
              return (
                <div key={'ld-'+lot.code+'-article-'+article.code}>
                {article.prices && article.prices[0] && article.prices[0][0] !== undefined
                  ?
                  <Article 
                    departmentCode={departmentCode} 
                    lot={lot} 
                    article={article} 
                    isAuthenticated={isAuthenticated} 
                    cartSelector={cartSelector} 
                    setDetailToggleArticleId={setDetailToggleArticleId} 
                    detailToggleArticleId={detailToggleArticleId}
                    user={user}
                  />
                  :
                  <ArticleWithoutSupplier 
                    departmentCode={departmentCode} 
                    lot={lot} 
                    article={article} 
                    isAuthenticated={isAuthenticated} 
                    setDetailToggleArticleId={setDetailToggleArticleId} 
                    detailToggleArticleId={detailToggleArticleId} 
                    userIsFormalized={user?.type ? user.type === 'formalized' : null}
                  />
                }
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>

  );
};

LotsDetails.propTypes = {};

LotsDetails.defaultProps = {};

export default LotsDetails;

import * as types from "./types";

export const getSupplierAccounts = (supplierId) => {
  return {
    type: types.GET_SUPPLIER_ACCOUNTS,
    meta: {
      async: true,
      path: `suppliers/${supplierId}/accounts`,
      method: "GET",
      body: {},
    },
  };
};

export const getSupplierDepartments = (supplierId) => {
  return {
    type: types.GET_SUPPLIER_DEPARTMENTS,
    meta: {
      async: true,
      path: `suppliers/${supplierId}/departments`,
      method: "GET",
    }
  };
};

export const getSupplierOrders = (supplierId) => ({
  type: types.GET_SUPPLIER_ORDERS,
  meta: {
    async: true,
    blocking: true,
    path: `supersupplier/supplierorders/${supplierId}`,
    method: "GET",
  },
});

export const addSupplierAccount = (data) => {
  return {
    type: types.ADD_SUPPLIER_ACCOUNT,
    meta: {
      async: true,
      path: `suppliers`,
      method: "POST",
      body: data,
    },
  };
};

export const updateSupplierAccount = (supplierId, data) => {
  return {
    type: types.UPDATE_SUPPLIER_ACCOUNT,
    meta: {
      async: true,
      path: `suppliers/${supplierId}`,
      method: "PUT",
      body: data,
      supplierId: supplierId,
    },
  };
};

export const removeSupplierAccount = (supplierId) => {
  return {
    type: types.REMOVE_SUPPLIER_ACCOUNT,
    meta: {
      async: true,
      path: `suppliers/${supplierId}`,
      method: "DELETE",
      customerId: supplierId,
    },
  };
};

export const reatributeDepartment = (adminId, departmentCode, supplierId) => {
  return {
    type: types.REATRIBUTE_DEPARTMENT,
    meta: {
      async: true,
      path: `suppliers/${adminId}/reatribute`,
      method: "PUT",
      body: { departmentCode: departmentCode, supplierId: supplierId},
    },
  };
};


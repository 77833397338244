import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import { modalsOperations } from "state/ducks/modal";
import { sessionOperations } from "state/ducks/session";
import { storeOperations } from "state/ducks/store";
import "./Header.css";
import CreateCart from "./CreateCart/CreateCart";
import { centimesToCurrency } from "utils/Utils";
import { centimesToCurrency4decimaux } from "utils/Utils";

const Header = (props) => {
    const {
        isAuthenticated,
        user,
        activeCart,
        pendingCarts,
        activeFreeCart,
        pendingFreeCarts,
    } = props;

    const dispatch = useDispatch();
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenPanier, setDropdownOpenPanier] = useState(false);
    // const [dropdownOpenFormalisees, setDropdownOpenFormalisees] = useState(false);
    // const [dropdownOpenLibres, setDropdownOpenLibres] = useState(false);
    const [itemQuantity, setItemQuantity] = useState(0);
    const [freeItemQuantity, setFreeItemQuantity] = useState(0);
    const [isSupplier, setIsSupplier] = useState(null);

    const toggleMain = () => {
        setIsOpen(!isOpen);
    };

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const togglePanier = () => {
        setDropdownOpenPanier(!dropdownOpenPanier);
    };

    const resetFilters = () => {
        dispatch(storeOperations.filtersLots("all", ""));
    };


    useEffect(() => {
        let total = 0;
        let totalF = 0;
        if (activeCart && activeCart.orderLots && activeCart.orderLots.length > 0) {
            activeCart.orderLots.forEach((lot) => {
                if (lot.orderArticles && lot.orderArticles.length > 0) {
                    total += lot.orderArticles.length;
                }
            });
        }
        if (activeFreeCart && activeFreeCart.orderFreeSuppliers.length > 0) {
            activeFreeCart.orderFreeSuppliers.forEach((orderFreeSupplier) => {
                if (
                    orderFreeSupplier.orderFreeArticles &&
                    orderFreeSupplier.orderFreeArticles.length > 0
                ) {
                    totalF += orderFreeSupplier.orderFreeArticles.length;
                }
            });
        }
        setItemQuantity(total);
        setFreeItemQuantity(totalF);
    }, [activeCart, activeFreeCart]);

    useEffect(() => {
        setIsSupplier(
            user && user.email && user.roles && user.roles.includes("ROLE_SUPPLIER")
        );
    }, [user]);

    return (
        <header id="header-app" className="fixed-top">
            <Navbar light expand="md">
                <Link
                    to="/"
                    onClick={resetFilters}
                    className="navbar-brand"
                    style={{
                        lineHeight: "1",
                        display: "flex",
                        alignItems: "center",
                        padding: "0",
                    }}
                >
                    <span style={{ fontFamily: "CocoGoo", color: "grey" }}>
                        Centralis
                        <sup style={{ verticalAlign: "bottom", fontSize: "0.4em" }}>®</sup>
                    </span>
                </Link>
                <NavbarToggler onClick={toggleMain} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar>
                        <NavItem>
                            <Link
                                to="/"
                                className={
                                    location.pathname === "/" ? "nav-link active" : "nav-link"
                                }
                            >
                                Présentation
                            </Link>
                        </NavItem>
                        {(user === null || (user.type === "formalized")) && (
                            <div>
                                <NavItem>
                                    <Link
                                        to="/catalogue"
                                        className={
                                            location.pathname === "/catalogue"
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                    >
                                        Catalogue
                                    </Link>

                                </NavItem>
                                {(!user?.roles || user?.roles.includes("ROLE_CUSTOMER")) && (
                                    <NavItem>
                                        <Link
                                            to="/prestataires/formalise"
                                            className={
                                                location.pathname === "/prestataires/formalise"
                                                    ? "nav-link active"
                                                    : "nav-link"
                                            }
                                        >
                                            Prestataires titulaires
                                        </Link>

                                    </NavItem>
                                )}
                            </div>
                        )}
                        <NavItem>
                            <Link
                                to="/catalogue/libre"
                                className={
                                    location.pathname === "/catalogue/libre"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                            >
                                Prestataires libres
                            </Link>
                        </NavItem>

                        <NavItem>
                            <Link
                                to="/acheteurs"
                                className={
                                    location.pathname === "/acheteurs"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                            >
                                Acheteurs
                            </Link>
                        </NavItem>
                    </Nav>
                    {isAuthenticated === false ? (
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <NavLink
                                    onClick={() =>
                                        dispatch(
                                            modalsOperations.getModalStatus("register", true, "big")
                                        )
                                    }
                                    className="nav-link"
                                >
                                    Inscription
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    onClick={() =>
                                        dispatch(
                                            modalsOperations.getModalStatus("login", true, null)
                                        )
                                    }
                                    className="nav-link"
                                >
                                    Connexion
                                </NavLink>
                            </NavItem>
                        </Nav>
                    ) : (
                        <Nav className="ml-auto" navbar>
                            {isSupplier && (
                                <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle nav caret={true}>
                                        Bienvenue <b>{user && `${user.email} (${user.codeId})`}</b>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {user !== null && user.type === "formalized" && (
                                            <Link
                                                className="dropdown-item"
                                                to={"/catalogue"}
                                                onClick={() =>
                                                    dispatch(
                                                        modalsOperations.getModalStatus(
                                                            "panier_create_as_supplier",
                                                            true,
                                                            null
                                                        )
                                                    )
                                                }
                                            >
                                                Nouveau panier formalisé
                                            </Link>
                                        )}
                                        <Link
                                            className="dropdown-item"
                                            to={"/catalogue/libre"}
                                            onClick={() =>
                                                dispatch(
                                                    modalsOperations.getModalStatus(
                                                        "panier_libre_create_as_supplier",
                                                        true,
                                                        null
                                                    )
                                                )
                                            }
                                        >
                                            Nouveau panier libre
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={"/compte/fournisseur/paniers"}
                                        >
                                            Mes Commandes
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={"/compte/fournisseur/articles"}
                                        >
                                            Mon catalogue libres
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={"/compte/fournisseur/informations"}
                                        >
                                            Mon profil
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={"/compte/fournisseur/documents"}
                                        >
                                            Mes documents
                                        </Link>
                                        {/* {user && user.roles.includes("ROLE_SUPER_SUPPLIER") && (
                                            <Link to={"/compte/fournisseur/comptes"} className="dropdown-item">
                                                Gestion des utilisateurs
                                            </Link>
                                        )} */}
                                        <div
                                            className="dropdown-item"
                                            onClick={() => dispatch(sessionOperations.logout())}
                                        >
                                            Déconnexion
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            )}
                            {user &&
                                user.email &&
                                user.roles &&
                                user.roles.includes("ROLE_CUSTOMER") && (
                                    <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>

                                        <DropdownToggle nav caret={true}>
                                            Bienvenue <b>{user && user.email}</b>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            {user !== null && user.type === "formalized" && (
                                                <Link
                                                    className="dropdown-item"
                                                    to={"/catalogue"}
                                                    onClick={() =>
                                                        dispatch(
                                                            modalsOperations.getModalStatus(
                                                                "panier_create",
                                                                true,
                                                                null
                                                            )
                                                        )
                                                    }
                                                >
                                                    Nouveau panier formalisé
                                                </Link>
                                            )}
                                            <Link
                                                className="dropdown-item"
                                                to={"/catalogue/libre"}
                                                onClick={() =>
                                                    dispatch(
                                                        modalsOperations.getModalStatus(
                                                            "panier_libre_create",
                                                            true,
                                                            null
                                                        )
                                                    )
                                                }
                                            >
                                                Nouveau panier libre
                                            </Link>
                                            <a
                                                className="dropdown-item"
                                                href="mailto:contact@centralis.site?subject=Prestation manquante"
                                            >
                                                Nouveau marché subséquent
                                            </a>
                                            <Link
                                                className="dropdown-item"
                                                to={"/compte/paniers"}
                                            >
                                                Mes Commandes
                                            </Link>
                                            <Link
                                                className="dropdown-item"
                                                to={"/compte/informations"}
                                            >
                                                Mon profil
                                            </Link>
                                            <div
                                                className="dropdown-item"
                                                onClick={() => dispatch(sessionOperations.logout())}
                                            >
                                                Déconnexion
                                            </div>
                                        </DropdownMenu>
                                    </Dropdown>
                                )}
                            <Dropdown nav isOpen={dropdownOpenPanier} toggle={togglePanier}>
                                <DropdownMenu right>
                                    <div className="toogle-panier" style={{ width: "300px" }}>
                                        <CreateCart
                                            activeCart={activeCart}
                                            pendingCarts={pendingCarts}
                                            activeFreeCart={activeFreeCart}
                                            pendingFreeCarts={pendingFreeCarts}
                                            user={user}
                                        />
                                        {activeCart !== null ? (
                                            <div>
                                                <h6>{activeCart.name}</h6>
                                                <p>DEPARTEMENT {activeCart.postal}</p>
                                                <p className="articleLot">
                                                    <strong>{itemQuantity}</strong>{" "}
                                                    {itemQuantity > 1 ? "ARTICLES" : "ARTICLE"}
                                                </p>
                                                <p className="articleLot">
                                                    <strong>{activeCart.orderLots.length}</strong>{" "}
                                                    {activeCart.orderLots.length > 1
                                                        ? "LOTS CONCERNES"
                                                        : "LOT CONCERNE"}
                                                </p>
                                                <h6>
                                                    Montant Total HT :{" "}
                                                    <span className="price">
                                                        {centimesToCurrency(activeCart.price)}
                                                    </span>
                                                </h6>
                                                <p
                                                    style={{ textAlign: "center", marginTop: "1.875em" }}
                                                >
                                                    <Link
                                                        className="btn-white"
                                                        to={`/compte/panier/${activeCart.id}`}
                                                    >
                                                        Voir le panier en cours
                                                    </Link>
                                                </p>
                                            </div>
                                        ) : null}
                                        {activeFreeCart !== null ? (
                                            <div>
                                                <h6>{activeFreeCart.name}</h6>
                                                <p>DEPARTEMENT {activeFreeCart.postal}</p>
                                                <p className="articleLot">
                                                    <strong>{freeItemQuantity}</strong>{" "}
                                                    {freeItemQuantity > 1 ? "ARTICLES" : "ARTICLE"}
                                                </p>
                                                <h6>
                                                    Montant Total HT :{" "}
                                                    <span className="price">
                                                        {centimesToCurrency(activeFreeCart.price)}
                                                    </span>
                                                </h6>
                                                <p
                                                    style={{ textAlign: "center", marginTop: "1.875em" }}
                                                >
                                                    <Link
                                                        className="btn-white"
                                                        to={`/compte/panier/libre/${activeFreeCart.id}`}
                                                    >
                                                        Voir le panier en cours
                                                    </Link>
                                                </p>
                                            </div>
                                        ) : null}
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </Nav>
                    )}
                </Collapse>
            </Navbar>
        </header>
    );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;

import * as types from "./types";

// Action de se connecter
export const login = (username, password, redirectUrl) => ({
  type: types.LOGIN,
  meta: {
    async: true,
    auth: true,
    path: `login`,
    method: "POST",
    body: {
      email: username,
      password: password,
    },
  },
  payload: {
    redirectUrl,
  },
  nextAction: {
    type: types.INITIALIZE_SESSION,
    meta: {
      async: true,
      blocking: true,
      path: `me`,
      method: "GET",
    },
  },
});

export const logout = () => ({
  type: types.LOGOUT,
});

export const registerCustomer = (data) => ({
  type: types.REGISTER_CUSTOMER,
  meta: {
    async: true,
    path: `customers`,
    method: "POST",
    body: data,
  },
});

export const registerSupplier = (data) => ({
  type: types.REGISTER_SUPPLIER,
  meta: {
    async: true,
    path: `suppliers`,
    method: "POST",
    body: { ...data, type: "free" },
  },
});

export const initializeSession = () => {
  if (window.localStorage.getItem("token") !== null) {
    return {
      type: types.INITIALIZE_SESSION,
      meta: {
        async: true,
        blocking: true,
        path: `me`,
        method: "GET",
      },
    };
  }
  return {
    type: types.INITIALIZE_SESSION_COMPLETED,
  };
};

// Retourne le type de l'utilisateur connecté
export const getUserType = () => {
  return {
    type: types.GET_USER_TYPE,
    meta: {
      async: true,
      path: `me/type`,
      method: "GET"
    },
  };
};

// Ajoute un article au panier. Si c'est le premier article de ce lot, le lot doit etre créé en même temps
export const addArticle = (articleData, cartId, cartType, supplierRank) => {
  if (cartType === "free") {
    let body = {
      freeArticle: `/api/free_articles/${articleData.articleId}`,
      quantity: articleData.quantity,
      price: articleData.price,
    };
    if (articleData.orderFreeSupplierId !== undefined) {
      body.orderFreeSupplier = `/api/order_free_suppliers/${articleData.orderFreeSupplierId}`;
    } else {
      body.orderFreeSupplier = {
        supplier: `/api/suppliers/${articleData.supplierId}`,
        freeCart: `/api/free_carts/${cartId}`,
      };
    }
    return {
      type: types.ADD_ARTICLE,
      meta: {
        async: true,
        path: `order_free_articles`,
        method: "POST",
        body: body,
      },
      payload: {
        cartType: cartType,
      },
    };
  }
  let body = {
    article: `/api/articles/${articleData.articleId}`,
    quantity: articleData.quantity,
    price: articleData.price,
  };
  if (articleData.orderLotId !== undefined) {
    body.orderLot = `/api/order_lots/${articleData.orderLotId}`;
  } else {
    body.orderLot = {
      lot: `/api/lots/${articleData.lotId}`,
      cart: `/api/carts/${cartId}`,
      proposalSupplierRank: supplierRank,
    };
    if (articleData.supplierId !== undefined) {
      body.orderLot.supplierManager = `/api/suppliers/${articleData.supplierId}`;
    }
  }
  return {
    type: types.ADD_ARTICLE,
    meta: {
      async: true,
      path: `order_articles`,
      method: "POST",
      body: body,
    },
    payload: {
      cartType: "formalized",
    },
  };
};

export const removeArticle = (orderArticleId, cartType) => {
  if (cartType === "free") {
    return {
      type: types.REMOVE_ARTICLE,
      meta: {
        async: true,
        path: `order_free_articles/${orderArticleId}`,
        method: "DELETE",
      },
      payload: {
        orderArticleId: orderArticleId,
        cartType: cartType,
      },
    };
  }
  return {
    type: types.REMOVE_ARTICLE,
    meta: {
      async: true,
      path: `order_articles/${orderArticleId}`,
      method: "DELETE",
    },
    payload: {
      orderArticleId: orderArticleId,
      cartType: "formalized",
    },
  };
};

export const removeLot = (orderLotId) => {
  return {
    type: types.REMOVE_LOT,
    meta: {
      async: true,
      path: `order_lots/${orderLotId}`,
      method: "DELETE",
    },
    payload: {
      orderLotId: orderLotId,
    },
  };
};

export const removeFreeSupplier = (orderFreeSupplierId) => {
  return {
    type: types.REMOVE_FREE_SUPPLIER,
    meta: {
      async: true,
      path: `order_free_suppliers/${orderFreeSupplierId}`,
      method: "DELETE",
    },
    payload: {
      orderFreeSupplierId: orderFreeSupplierId,
    },
  };
};

export const changeActiveCart = (
  customerId,
  customerRoles,
  cartId,
  cartType
) => {
  let resource = "customers";
  if (customerRoles.includes("ROLE_SUPPLIER")) {
    resource = "suppliers";
  }
  if (cartType === "free") {
    return {
      type: types.CHANGE_ACTIVE_CART,
      meta: {
        async: true,
        path: `${resource}/${customerId}`,
        method: "PUT",
        body: {
          activeFreeCart: `api/free_carts/${cartId}`,
        },
      },
    };
  }
  return {
    type: types.CHANGE_ACTIVE_CART,
    meta: {
      async: true,
      path: `${resource}/${customerId}`,
      method: "PUT",
      body: {
        activeCart: `api/carts/${cartId}`,
      },
    },
  };
};

export const confirmCart = (cartId, cartType) => {
  if (cartType === "free") {
    return {
      type: types.CONFIRM_CART,
      meta: {
        async: true,
        path: `free_carts/${cartId}`,
        method: "PUT",
        body: {
          status: 2,
        },
      },
    };
  }
  return {
    type: types.CONFIRM_CART,
    meta: {
      async: true,
      path: `carts/${cartId}`,
      method: "PUT",
      body: {
        status: 2,
      },
    },
  };
};

/**
 * Le status 5 du fournisseur est géré par l'entité sur l'API sur la méthode setSupplier
 * @param {*} data
 * @param {*} cartType
 * @returns
 */
export const createCart = (data, cartType) => {
  let defaultEdition;
  if (!data?.edition) {
    if (process.env.REACT_APP_EDITION === "current") {
      const date = new Date();
      let year = date.getFullYear();
      year = Number(year.toString().slice(-2));
      if (year >= 23) {
        defaultEdition = "23";
      } else {
        defaultEdition = "21";
      }
    } else {
      defaultEdition = process.env.REACT_APP_EDITION;
    }
  } else {
    defaultEdition = data.edition;
  }
  data.edition = parseInt(defaultEdition);
  if (cartType === "free") {
    return {
      type: types.CREATE_CART,
      meta: {
        async: true,
        path: `free_carts`,
        method: "POST",
        body: data,
      },
      payload: {
        cartType: "free",
      },
    };
  }
  return {
    type: types.CREATE_CART,
    meta: {
      async: true,
      path: `carts`,
      method: "POST",
      body: data,
    },
    payload: {
      cartType: "formalized",
    },
  };
};

export const updateCart = (cartId, data, cartType) => {
  if (cartType === "free") {
    return {
      type: types.UPDATE_CART,
      meta: {
        async: true,
        path: `free_carts/${cartId}`,
        method: "PUT",
        body: data,
      },
    };
  }

  return {
    type: types.UPDATE_CART,
    meta: {
      async: true,
      path: `carts/${cartId}`,
      method: "PUT",
      body: data,
    },
  };
};

export const removeCart = (cartId, cartType) => {
  if (cartType === "free" || cartType === "freePending" || cartType === "freeProposal") {
    return {
      type: types.REMOVE_CART,
      meta: {
        async: true,
        path: `free_carts/${cartId}`,
        method: "DELETE",
      },
    };
  }
  return {
    type: types.REMOVE_CART,
    meta: {
      async: true,
      path: `carts/${cartId}`,
      method: "DELETE",
    },
  };
};

export const printCart = (cartId, cartType) => {
  if (cartType === "free") {
    return {
      type: types.PRINT_CART,
      meta: {
        async: true,
        path: `free_carts/${cartId}/print`,
        method: "GET",
      },
    };
  }
  return {
    type: types.PRINT_CART,
    meta: {
      async: true,
      path: `carts/${cartId}/print`,
      method: "GET",
    },
  };
};

export const takeCart = (cartId, cartType) => {
  if (cartType === "free") {
    return {
      type: types.TAKE_CART,
      meta: {
        async: true,
        path: `free_carts/${cartId}/take`,
        method: "POST",
        body: {},
      },
    };
  }
  return {
    type: types.TAKE_CART,
    meta: {
      async: true,
      path: `carts/${cartId}/take`,
      method: "POST",
      body: {},
    },
  };
};

export const giveCart = (cartId, cartType) => {
  if (cartType === "free") {
    return {
      type: types.GIVE_CART,
      meta: {
        async: true,
        path: `free_carts/${cartId}/give`,
        method: "POST",
        body: {},
      },
    };
  }
  return {
    type: types.GIVE_CART,
    meta: {
      async: true,
      path: `carts/${cartId}/give`,
      method: "POST",
      body: {},
    },
  };
};

export const updateCustomer = (userId, data) => {
  return {
    type: types.UPDATE_CUSTOMER,
    meta: {
      async: true,
      path: `customers/${userId}`,
      method: "PUT",
      body: data,
    },
  };
};

export const resetPassword = (email) => {
  return {
    type: types.RESET_PASSWORD,
    meta: {
      async: true,
      path: `custom/resetpassword/`,
      method: "POST",
      body: email,
    },
  };
};

export const resetPasswordWithContract = (contractId) => {
  return {
    type: types.RESET_PASSWORD_WITH_CONTRACT,
    meta: {
      async: true,
      path: `custom/resetpassword/contract`,
      method: "POST",
      body: contractId
    },
  }
};

export const getConfirmedCarts = (userId, pageNumber, cartType) => {
  if (cartType === "free") {
    return {
      type: types.GET_CONFIRMED_CARTS,
      meta: {
        async: true,
        path: `customers/${userId}/free_carts`,
        method: "GET",
        params: {
          page: pageNumber,
          status: 2,
        },
      },
      payload: {
        pageNumber: pageNumber,
        cartType: cartType,
      },
    };
  }
  return {
    type: types.GET_CONFIRMED_CARTS,
    meta: {
      async: true,
      path: `customers/${userId}/carts`,
      method: "GET",
      params: {
        page: pageNumber,
        status: 2,
      },
    },
    payload: {
      pageNumber: pageNumber,
      cartType: cartType,
    },
  };
};

export const loadCart = (cartId, cartType) => {
  if (cartType === "free") {
    return {
      type: types.LOAD_CART,
      meta: {
        async: true,
        path: `free_carts/${cartId}`,
        method: "GET",
      },
    };
  }
  return {
    type: types.LOAD_CART,
    meta: {
      async: true,
      path: `carts/${cartId}`,
      method: "GET",
    },
  };
};

export const addFreeArticle = (freeArticle) => {
  return {
    type: types.ADD_FREE_ARTICLE,
    meta: {
      async: true,
      path: `free_articles`,
      method: "POST",
      body: freeArticle,
    },
  };
};

export const updateFreeArticle = (freeArticleId, freeArticle) => {
  return {
    type: types.UPDATE_FREE_ARTICLE,
    meta: {
      async: true,
      path: `free_articles/${freeArticleId}`,
      method: "PUT",
      body: freeArticle,
    },
  };
};

export const removeFreeArticle = (freeArticleId) => {
  return {
    type: types.REMOVE_FREE_ARTICLE,
    meta: {
      async: true,
      path: `free_articles/${freeArticleId}`,
      method: "DELETE",
    },
    payload: {
      freeArticleId: freeArticleId,
    },
  };
};

export const updateSupplier = (userId, data) => {
  return {
    type: types.UPDATE_SUPPLIER,
    meta: {
      async: true,
      path: `suppliers/${userId}`,
      method: "PUT",
      body: data,
    },
  };
};

export const sendCartToCustomer = (cartId, cartType, email) => {
  if (cartType === "free") {
    return {
      type: types.SEND_CART_TO_CUSTOMER,
      meta: {
        async: true,
        path: `free_carts/${cartId}/retrieve`,
        method: "POST",
        body: {
          status: 10,
          proposalEmail: 'emailjules@gmail.com',
          customer_id: 874,
        },
      },
    };
  }
  return {
    type: types.SEND_CART_TO_CUSTOMER,
    meta: {
      async: true,
      path: `free_carts/${cartId}/retrieve`,
      method: "POST",
      body: {
        status: 10,
        proposalEmail: 'emailjules@gmail.com',
        customer_id: 874,
      },
    },
  };
};

export const retrieveCart = (cart_id, cartType, user_id, user_email) => {
  let cartId = cart_id.slice(2, -1);
  if (cartType === "free") {
    return {
      type: types.RETRIEVE_CART,
      meta: {
        async: true,
        path: `free_carts/${cartId}/retrieve`,
        method: "POST",
        body: {
          customer_id: user_id,
          cart_id: cart_id,
          cartType: cartType,
          proposal_email: user_email,
        },
      },
    };
  }
  else {
    return {
      type: types.RETRIEVE_CART,
      meta: {
        async: true,
        path: `carts/${cartId}/retrieve`,
        method: "POST",
        body: {
          customer_id: user_id,
          cart_id: cart_id,
          cartType: cartType,
          proposal_email: user_email,
        },
      },
    }
  }
};

export const responseToProposalCart = (cartId, cartType, response) => {
  if (cartType === "free") {
    return {
      type: types.RESPONSE_PROPOSAL_CART,
      meta: {
        async: true,
        path: `free_carts/${cartId}/proposal/${response}`,
        method: "PUT",
        body: {},
      },
    };
  }
  return {
    type: types.RESPONSE_PROPOSAL_CART,
    meta: {
      async: true,
      path: `carts/${cartId}/proposal/${response}`,
      method: "PUT",
      body: {},
    },
  };
};

export function centimesToCurrency4decimaux(amount) {
  return (amount / 100).toFixed(4) + ' €';
}

export function centimesToCurrency(amount) {
  const currency = amount / 100; // Diviser par 100
  const decimalPlaces = currency.toString().split(".")[1]?.length || 0; // Compter les décimales
  
  if (decimalPlaces > 2) {
    // Formater à 4 décimales si plus de 2
    return currency.toFixed(4) + ' €';
  } else if (decimalPlaces === 0) {
    // Si pas de décimales, afficher l'entier
    return Math.trunc(currency) + ' €';
  } else {
    // Formater à 2 décimales sinon
    return currency.toFixed(2) + ' €';
  }
}
